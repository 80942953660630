import { useRouter } from 'next/router';

import { triggerSitewideBannerLinkClickEvent } from 'Services/analytics/events/sitewideBannerEvents';
import { isCurrentPath } from 'Utilities/helpers/isCurrentPath';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import ROUTES from 'Utilities/routes';

import type { BannerConfig } from '../SitewideBanner.types';

const id = 'motorwayMoveLaunchLandingPage';

const badge: BannerConfig['badge'] = {
	text: 'NEW!',
	variant: 'primary',
};

const texts = {
	linkLabel: 'Click here to get £10 off every time you use Motorway Move',
	text: 'Our hassle-free transport and appraisals service is back – and with better prices.',
	title: 'Introducing Motorway Move',
};

const route = { as: ROUTES.MOTORWAY_MOVE.as(), href: ROUTES.MOTORWAY_MOVE.href };

export const useMotorwayMoveBannerConfig = (): BannerConfig => {
	const router = useRouter();
	const isEnabled = useFeatureToggle(FEATURES.transportExperience);

	const onLinkClick = async (): Promise<void> => {
		const { as, href } = route;
		const path = as || href;

		triggerSitewideBannerLinkClickEvent(id);

		if (isCurrentPath(path) || window.location?.pathname === as) {
			// If the link is the same as the current path, we need to request a new p age as internal routing causes an error.
			// Also, there is currently an issue when shallow routing to vehicle filte r lists from the marketplace.
			return;
		}

		await router.push(href, as, { shallow: true });
	};

	return { badge, id, isEnabled, linkProps: { onClick: onLinkClick }, texts };
};
