import { MDText } from 'i18n-react';

import { ACCOUNT_SETTINGS_COMPANY_INFORMATION_LINK } from 'Components/pages/AccountSettings/AccountSettings.consts';
import { useHandleKycStatus } from 'Utilities/hooks/useHandleKycStatus';
import useKycPermission from 'Utilities/hooks/useKycPermission';

import LocalTexts from '../SitewideBanner.texts';
import type { BannerConfig } from '../SitewideBanner.types';

const localT = new MDText(LocalTexts.kycCTA);

const id = 'kycCTA';

const texts = {
	linkLabel: `${localT.translate('linkLabel')}`,
	text: `${localT.translate('text')}`,
	title: `${localT.translate('title')}`,
};

export const useKycBannerConfig = (): BannerConfig => {
	const { isKycInProgress } = useHandleKycStatus({
		source: 'useKycBannerConfig',
	});

	const hasKycPermission = useKycPermission();

	return {
		id,
		ignoreInTests: true,
		isDismissible: false,
		isEnabled: isKycInProgress() && hasKycPermission,
		linkProps: { href: ACCOUNT_SETTINGS_COMPANY_INFORMATION_LINK },
		priorityIndex: 99999,
		shouldExpire: false,
		texts,
	};
};
